<template>
  <div class="page">
    <el-radio-group v-model="is_wenli" @change="getList">
      <el-radio-button label="1">文科</el-radio-button>
      <el-radio-button label="2">理科</el-radio-button>
    </el-radio-group>
    <div class="choose">
      <div @click="(order = 'id'), getList()" :class="{ fx: order == 'id' }">
        默认排序
      </div>
      <div @click="(order = 'score'), getList()" :class="{ fx: order == 'score' }">
        分数排序
      </div>
    </div>
    <user_list :UserData="UserData"> </user_list>
    <div class="pagination">
      <el-pagination :page-size="tables.pageSize" :total="tables.total" class="page-box" layout="prev, pager, next"
        @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import user_list from "./components/UserList";
//time: 2021/1/22
export default {
  name: "User",
  components: {
    user_list,
  },
  data() {
    return {
      is_wenli: 1,
      UserData: [],
      ExpertInfo: "",
      order: "id",
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 12,
        total: 1,
        tableData: [],
      },
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        is_vip: 1,
        parent_id: this.ExpertInfo.id,
        is_wenli: this.is_wenli,
        order: this.order,
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };

      this.$fecth.post("user/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.UserData = data.lists;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    pageChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.choose {
  background: white;
  margin-bottom: 12px;
  display: flex;
  line-height: 25px;
  padding: 3px;

  .fx {
    border-bottom: 1px solid rgb(11, 108, 218);
  }

  div {
    width: 80px;
    margin: 5px 0 5px 75px;
  }
}

.pagination {
  background: white;
  padding: 0;
}
</style>